.admin-question__section {
padding: 0 0 2rem 0;

  &__title {
    font-family: $regular;
    font-size: 1rem;
  }

  &__question {
    font-family: $semibold;
    font-size: 1.125rem;
    color: $blue;
	  padding: 0.5rem 0 1rem 0;
  }

  &__statement {
    font-family: $medium;
  }

  &__answer {
    font-family: $bold;
    font-size: 2rem;
  }

  &__answers {
    display: block;
	margin-bottom: 0.75rem;

    &__statement {
      color: $black;
      font-family: $medium;
      margin-right: 0.5rem;
    }
    &__response {
      font-family: $bold;
      color: $blue;
      text-decoration: underline;
    }
  }

}

.admin-page-section__statistics-section {
	display: block;
	margin-bottom: 2rem;



	&__question {
		font-size: 1.5rem;
		font-weight: 700;
    margin-bottom: .5rem;
	}

	&__answer {
		font-size: 1rem;
		font-weight: 400;
    	margin-bottom: .25rem;
		line-height: 1.5;

		&--border-bottom::after {
          margin: 1rem 0;
          display: block;
          content: '';
          width: 100px;
          height: 2px;
          background-color: $light-gray;
          //border-bottom: 1px solid $light-gray;
        }


		&__statement {
			//
			font-weight: 700;
		}

		&__value {
			color: $blue;
			font-weight: 700;
			text-decoration: underline;
		}

		&__totals-list {
			list-style: none;
      margin-bottom: 1rem;
      padding-top: .5rem;
      padding-left: 1rem;
			&__item {
				//
        		margin-bottom: .25rem;

				&__value {
					font-weight: 700;
					color: $blue;
					text-decoration: underline;
				}
			}
		}
	}
}
