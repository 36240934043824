// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$xs: 321px;
$s: 500px;
$m: 800px;
$l: 1000px;
$xl: 1410px;
// Default content settings.
$content-padding: 1rem;
$content-max-width: 62.5rem; /* 1000/16 */
$grid-max-width: $content-max-width + ($content-padding*2);

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

// Colors
$blue: #0070FA;
$black: #292828;

$lighter-gray: #DDDDDD;
$light-gray: #d6d2d0;
$gray: #9a9492;
$white: #fff;
